




























































import { Component, Vue } from 'vue-property-decorator'
import ReferenceService from '@/api/services/ReferenceService'
import Site from '@/api/models/Site'
import { Getter } from 'vuex-class'
import Connection from '@/api/models/Connection'
import { getBrandName, setFavicon, setTitle } from '@/shared/components/helper'

@Component({
})
export default class Step2 extends Vue {
    private sites: Array<Site> = []
    private selectedSite: Site = { code: '', name: '' }
    private clientId = ''
    private apiKey = ''

    @Getter('connection')
    connection!: Connection

    get fullBrand (): string {
        return getBrandName(this.connection)
    }

    async created () {
        const referenceService = new ReferenceService()
        this.sites = await referenceService.sitesList()
    }

    mounted (): void {
        setFavicon(this.connection)
        setTitle(this.connection)
    }

    confirm () {
        if (this.selectedSite.code) {
            this.$emit('confirm', {
                ozonClientId: this.clientId,
                ozonKey: this.apiKey,
                site: this.selectedSite.code
            })
        }
    }
}
