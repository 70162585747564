



































import { Component, Vue } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import Step1 from '@/modules/connect/components/Step1.vue'
import Step2 from '@/modules/connect/components/Step2.vue'
import Connection from '@/api/models/Connection'
import Account from '@/api/models/Account'

@Component({
    components: {
        Step1,
        Step2
    }
})
export default class Connect extends Vue {
    private currentStep = 1;
    private steps: {[key: number]: string} = { 1: 'step1', 2: 'step2' };
    private error = '';

    @Getter('connection')
    connection!: Connection;

    @Getter('accounts')
    accounts!: Array<Account>;

    @Action('signIn')
    signIn!: (connection: Connection) => Promise<void>

    @Action('auth')
    auth!: (connection: Connection) => Promise<void>

    @Action('createAccount')
    public createAccount!: (account: Account) => void

    public async continue (connection: Connection) {
        try {
            await this.signIn(connection)
            if (this.connection) {
                await this.auth(this.connection)

                if (this.accounts.length > 0) {
                    await this.$router.push({ name: 'accounts' })
                } else {
                    this.currentStep++
                }
            }
        } catch (e) {
            console.log(e)
            this.error = this.$t('connect.connectError').toString()
        }
    }

    public async confirm (account: Account): Promise<void> {
        try {
            await this.createAccount(account)
            await this.$router.push({ name: 'account_install' })
        } catch (e) {
            console.log(e)
            this.error = this.$t('connect.connectError').toString()
        }
    }

    private closeAlert () {
        this.error = ''
    }
}
