














































import { Component, Vue } from 'vue-property-decorator'
import { getUrlPlaceholder, getBrandNameByReferrer, setFaviconByReferrer, setTitleByReferrer } from '@/shared/components/helper'

@Component({
})
export default class Step1 extends Vue {
    private apiUrl = '';
    private apiKey = '';
    private apiUrlError: string|null = null;
    private apiKeyError: string|null = null;

    mounted (): void {
        if (document.referrer) {
            setFaviconByReferrer(document.referrer)
            setTitleByReferrer(document.referrer)
        }
    }

    get fullBrandByReferrer (): string {
        return getBrandNameByReferrer(document.referrer)
    }

    get systemUrlPlaceholder (): string {
        return getUrlPlaceholder(document.referrer)
    }

    private async continue (): Promise<void> {
        this.apiUrlError = null
        this.apiKeyError = null

        if (this.apiUrl.length === 0) {
            this.apiUrlError = this.$t('connect.emptyApiUrlError').toString()
        }

        if (this.apiKey.length === 0) {
            this.apiKeyError = this.$t('connect.emptyApiKeyError').toString()
        }

        if (!this.apiUrlError && !this.apiKeyError) {
            this.$emit('continue', { apiUrl: this.apiUrl, apiKey: this.apiKey })
        }
    }
}
