import Connection from '@/api/models/Connection'

function isBrandAsSimla (apiUrl: string): boolean {
    const patternNewBrand = /^(.+)\.simla\.(.+)$/
    if (patternNewBrand.test(apiUrl)) {
        return true
    }

    return false
}

function getBrandKey (connection: Connection): string {
    if (isBrandAsSimla(connection.apiUrl)) {
        return 'simla'
    }

    return 'retailcrm'
}

function getBrandName (connection: Connection): string {
    if (isBrandAsSimla(connection.apiUrl)) {
        return 'Simla.com'
    }

    return 'RetailCRM'
}

function getDocLink (connection: Connection): string {
    if (isBrandAsSimla(connection.apiUrl)) {
        return 'https://www.simla.com/ru/docs/Users/Integration/Marketplaces/IntegrationWithOzonSeller'
    }
    return 'https://docs.retailcrm.ru/Users/Integration/Marketplaces/IntegrationWithOzonSeller'
}

function getUrlPlaceholder (refUrl: string) {
    if (isBrandAsSimla(refUrl)) {
        return 'https://demo.simla.com/'
    }

    return 'https://demo.retailcrm.ru/'
}

function getBrandNameByReferrer (refUrl: string): string {
    if (isBrandAsSimla(refUrl)) {
        return 'Simla.com'
    }

    return 'RetailCRM'
}

function getBrandKeyByReferrer (refUrl: string): string {
    if (isBrandAsSimla(refUrl)) {
        return 'simla'
    }

    return 'retailcrm'
}

function setFavicon (connection: Connection): void {
    const brandKey = getBrandKey(connection)
    const favicon = document.getElementById('favicon')
    if (favicon) {
        const faviconHref = favicon.attributes.getNamedItem('href')
        if (faviconHref) {
            faviconHref.value = `${process.env.VUE_APP_CDN}${brandKey}/favicon/favicon.ico`
        }
    }
}

function setTitle (connection: Connection): void {
    const fullBrand = getBrandName(connection)
    document.title = `Модуль интеграции для ${fullBrand} и OZON`
}

function setFaviconByReferrer (refUrl: string): void {
    const brandKey = getBrandKeyByReferrer(refUrl)
    const favicon = document.getElementById('favicon')
    if (favicon) {
        const faviconHref = favicon.attributes.getNamedItem('href')
        if (faviconHref) {
            faviconHref.value = `${process.env.VUE_APP_CDN}${brandKey}/favicon/favicon.ico`
        }
    }
}

function setTitleByReferrer (refUrl: string): void {
    const fullBrand = getBrandNameByReferrer(refUrl)
    document.title = `Модуль интеграции для ${fullBrand} и OZON`
}

export {
    getBrandKey,
    getBrandName,
    setFavicon,
    setTitle,
    getUrlPlaceholder,
    getBrandNameByReferrer,
    getBrandKeyByReferrer,
    setFaviconByReferrer,
    setTitleByReferrer,
    getDocLink
}
